import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

// Variable to store the Lenis smooth scrolling object
let lenis;

// ASCII art for the console
const asciiArt = `

 :::::::: ::::::::::: ::::::::  :::::::::        ::::::::  :::    ::: ::::::::::: ::::::::::: :::       :::     :::     :::::::::  ::::::::::
:+:    :+:    :+:    :+:    :+: :+:    :+:      :+:    :+: :+:    :+:     :+:         :+:     :+:       :+:   :+: :+:   :+:    :+: :+:
+:+           +:+    +:+    +:+ +:+    +:+      +:+        +:+    +:+     +:+         +:+     +:+       +:+  +:+   +:+  +:+    +:+ +:+
+#++:++#++    +#+    +#+    +:+ +#++:++#+       +#++:++#++ +#++:++#++     +#+         +#+     +#+  +:+  +#+ +#++:++#++: +#++:++#:  +#++:++#
       +#+    +#+    +#+    +#+ +#+                    +#+ +#+    +#+     +#+         +#+     +#+ +#+#+ +#+ +#+     +#+ +#+    +#+ +#+
#+#    #+#    #+#    #+#    #+# #+#             #+#    #+# #+#    #+#     #+#         #+#      #+#+# #+#+#  #+#     #+# #+#    #+# #+#
 ########     ###     ########  ###              ########  ###    ### ###########     ###       ###   ###   ###     ### ###    ### ##########

`;

// Select all .content elements
const contentElements = document.querySelectorAll('.content');
const contentInsides = document.querySelectorAll('.content *');

const totalContentElements = contentElements.length;

// Initializes Lenis for smooth scrolling with specific properties
const initSmoothScrolling = () => {
	// Instantiate the Lenis object with specified properties
	lenis = new Lenis({
		lerp: 0.1, // Lower values create a smoother scroll effect
		infinite: false, // Enables infinite scrolling
		smoothWheel: true, // Enables smooth scrolling for mouse wheel events
	});

	// Update ScrollTrigger each time the user scrolls
	lenis.on('scroll', () => ScrollTrigger.update());

	// Define a function to run at each animation frame
	const scrollFn = (time) => {
		lenis.raf(time); // Run Lenis' requestAnimationFrame method
		requestAnimationFrame(scrollFn); // Recursively call scrollFn on each frame
	};
	// Start the animation frame loop
	requestAnimationFrame(scrollFn);
};

// Helper function to create a GSAP timeline for an element
const createTimeline = (el, animationParams, snap) => {
	gsap
		.timeline({
			scrollTrigger: {
				trigger: el,
				start: 'top top',
				end: '+=100%',
				scrub: true,
				snap: snap
					? {
							snapTo: 1,
							duration: 2,
							ease: 'expo.inOut',
							delay: 2.5,
					  }
					: undefined,
			},
		})
		.to(el, animationParams, 0);
};

// Function to handle scroll-triggered animations
const scroll = () => {
	contentElements.forEach((el, position) => {
		const isLast = position === totalContentElements - 1;
		createTimeline(
			el,
			{
				ease: 'none',
				scale: 0.9,
				rotateX: '-35deg',
				borderRadius: '2.85rem',
				filter: isLast ? 'none' : 'brightness(0.2) saturate(300%) blur(1.5px)',
				'--shadowpercent': isLast ? '0%' : '115%',
				'--shadowoffset': isLast ? '0%' : '15%',

				startAt: {
					filter: 'brightness(1) saturate(100%)',
					borderRadius: '0.5rem',
				},
			},
			false
		);
	});
	contentInsides.forEach((el) => {
		createTimeline(
			el,
			{
				display: 'none',
			},
			false
		);
	});
};

/** Direction constants */
const NEXT = 1;
const PREV = -1;

/**
 * Slideshow Class
 * Manages slideshow functionality including navigation and animations.
 */
class Slideshow {
	DOM = {
		el: null, // Main slideshow container
		slides: null, // Individual slides
		slidesInner: null, // Inner content of slides (usually images)
	};
	/**
	 * Index of the current slide being displayed.
	 * @type {number}
	 */
	current = 0;
	/**
	 * Total number of slides.
	 * @type {number}
	 */
	slidesTotal = 0;

	/**
	 * Flag to indicate if an animation is running.
	 * @type {boolean}
	 */
	isAnimating = false;

	/**
	 * Slideshow constructor.
	 * Initializes the slideshow and sets up the DOM elements.
	 * @param {HTMLElement} DOM_el - The main element holding all the slides.
	 */
	constructor(DOM_el) {
		// Initialize DOM elements
		this.DOM.el = DOM_el;
		this.DOM.slides = [...this.DOM.el.querySelectorAll('.slide__content')];
		this.DOM.slidesInner = this.DOM.slides.map((item) =>
			item.querySelector('.slide__img')
		);

		// Set initial slide as current
		this.DOM.slides[this.current].classList.add('slide__current');

		// Count total slides
		this.slidesTotal = this.DOM.slides.length;
	}

	/**
	 * Navigate to the next slide.
	 * @returns {void}
	 */
	next() {
		this.navigate(NEXT);
	}

	/**
	 * Navigate to the previous slide.
	 * @returns {void}
	 */
	prev() {
		this.navigate(PREV);
	}

	/**
	 * Navigate through slides.
	 * @param {number} direction - The direction to navigate. 1 for next and -1 for previous.
	 * @returns {boolean} - Return false if the animation is currently running.
	 */
	navigate(direction) {
		// Check if animation is already running
		if (this.isAnimating) return false;
		this.isAnimating = true;

		// Update the current slide index based on direction
		const previous = this.current;
		this.current =
			direction === 1
				? this.current < this.slidesTotal - 1
					? ++this.current
					: 0
				: this.current > 0
				? --this.current
				: this.slidesTotal - 1;

		// Get the current and upcoming slides and their inner elements
		const currentSlide = this.DOM.slides[previous];
		const currentInner = this.DOM.slidesInner[previous];
		const upcomingSlide = this.DOM.slides[this.current];
		const upcomingInner = this.DOM.slidesInner[this.current];

		// Animation sequence using GSAP
		gsap
			.timeline({
				defaults: {
					duration: 1.25,
					ease: 'expo.inOut',
				},
				onStart: () => {
					// Add class to the upcoming slide to mark it as current
					this.DOM.slides[this.current].classList.add('slide__current');
					gsap.set(upcomingSlide, { zIndex: 99 });
				},
				onComplete: () => {
					// Remove class from the previous slide to unmark it as current
					this.DOM.slides[previous].classList.remove('slide__current');
					gsap.set(upcomingSlide, { zIndex: 1 });
					// Reset animation flag
					this.isAnimating = false;
				},
			})
			// Defining animation steps
			.addLabel('start', 0)
			.to(
				currentSlide,
				{
					duration: 0.65,
					ease: 'power1.inOut',
					scale: 0.9,

					borderRadius: '2rem',

					autoAlpha: 0.2,
				},
				'start'
			)
			.to(
				currentSlide,
				{
					yPercent: -direction * 20,

					autoAlpha: 0,
				},
				'start+=0.1'
			)
			.fromTo(
				upcomingSlide,
				{
					autoAlpha: 1,
					scale: 1,
					borderRadius: 'inherit',

					yPercent: direction * 100,
				},
				{
					yPercent: 0,
				},
				'start+=0.1'
			)
			.fromTo(
				upcomingInner,
				{
					yPercent: -direction * 50,
				},
				{
					yPercent: 0,
				},
				'start+=0.1'
			);
	}
}

const slideshow = () => {
	// Select all slideshows
	const slideshows = document.querySelectorAll('.slide');

	// Create a new Slideshow instance for each slideshow
	slideshows.forEach((slides, index) => {
		const slideshow = new Slideshow(slides);

		// Add event listeners to the navigation buttons of each slideshow
		document
			.querySelector(`.slide__nav__item__prev[data-slideshow="${index}"]`)
			.addEventListener('click', () => slideshow.prev());
		document
			.querySelector(`.slide__nav__item__next[data-slideshow="${index}"]`)
			.addEventListener('click', () => slideshow.next());
	});
};

// Select all .chips divs
const chipsDivs = document.querySelectorAll('.chips');

// Define the move function
const move = function (event) {
	let target = event.target,
		x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
		y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

	target.style.webkitTransform =
		target.style.transform = `translate(${x}px, ${y}px)`;

	target.setAttribute('data-x', x);
	target.setAttribute('data-y', y);
};

// For each .chips div
chipsDivs.forEach((chipsDiv) => {
	// Select all .chip elements in this div
	const chips = chipsDiv.querySelectorAll('.chip');

	// Make each .chip draggable
	chips.forEach((chip) => {
		interact(chip).draggable({
			inertia: true,
			modifiers: [
				interact.modifiers.restrictRect({
					restriction: 'parent',
					endOnly: true,
				}),
			],
			autoScroll: true,
			listeners: {
				move: move,
			},
		});
	});
});

// Color picker initialization
const picker = new iro.ColorPicker("#picker", {
  width: 250,
  color: "hsl(89, 51%, 88%)",
  layout: [{
    component: iro.ui.Slider,
    options: {
      sliderType: 'hue',
      handleRadius: 9,
      padding: 0,
      margin: 0,
      borderColor: 'var(--color-light)',
      borderWidth: 3,
    }
  }]
});

// Function to update CSS variables
function updateColors(hue) {
  const colorVariables = {
    '--color-accent': `hsl(${hue}, 51%, 88%)`,
    '--color-accent-2': `hsl(${hue}, 51%, 92%)`,
    '--color-light': `hsl(${(hue + 326) % 360}, 60%, 96%)`,
    '--color-dark': `hsl(${(hue + 162) % 360}, 12%, 73%)`,
    '--color-darkest': `hsl(${(hue - 2) % 360}, 52%, 4%)`
  };

  Object.entries(colorVariables).forEach(([property, value]) => {
    document.documentElement.style.setProperty(property, value);
  });
}

// Listen to color changes
picker.on(['color:init', 'color:change'], (color) => updateColors(color.hsl.h));

// Generic function for toggle animations
function toggleAnimation(isOpen, openAnimation, closeAnimation) {
  const animation = isOpen ? closeAnimation : openAnimation;
  Object.entries(animation).forEach(([selector, props]) => {
    gsap.to(document.querySelector(selector), props);
  });
  return !isOpen;
}

// Picker toggle
let isPickerOpen = false;
const footerPicker = document.getElementById('footerPicker');

function togglePicker() {
  gsap.to('#footerWave', {
    filter: 'blur(100px)',
    duration: 1,
    ease: 'power3.out'
  });

  const openAnimation = {
    '#pickerContainer': {
      width: '260px',
      height: '25px',
      opacity: 1,
      duration: 0.45,
      ease: 'power3.out'
    },
    '#footerPicker': {
      width: '260px',
      height: '25px',
      duration: 0.45,
      ease: 'power3.out'
    }
  };

  const closeAnimation = {
    '#pickerContainer': {
      width: 0,
      height: 0,
      opacity: 0,
      duration: 0.45,
      ease: 'power3.out'
    },
    '#footerPicker': {
      width: '16px',
      height: '16px',
      duration: 0.45,
      ease: 'power3.out'
    }
  };

  isPickerOpen = toggleAnimation(isPickerOpen, openAnimation, closeAnimation);
  footerPicker.classList.toggle('active');
}

['click', 'touchstart'].forEach(event => footerPicker.addEventListener(event, togglePicker));

// Navbar toggle
let isNavOpen = true;
const navToggle = document.getElementById('navToggle');

function toggleNav() {
  gsap.to('#navWave', {
    filter: 'blur(100px)',
    duration: 5,
    ease: 'power3.out',
  });

  const openAnimation = {
    '#navContainer': {
      top: '0',
      duration: 0.45,
      ease: 'power3.out',
    },
    '#navBlur': {
      top: '0',
      duration: 0.45,
      delay: 0.05,
      ease: 'power3.out'
    },
    '#nav': {
      background: "hsla(0, 0%, 0%, 0.4)",
      pointerEvents: 'auto',
      duration: 0.45,
      delay: 0.25,
      ease: 'power3.out'
    }
  };

  const closeAnimation = {
    '#navContainer': {
      top: '-210px',
      duration: 0.45,
      ease: 'power3.out',
    },
    '#navBlur': {
      top: '-40%',
      duration: 0.45,
      ease: 'power3.out',
      delay: 0.05,
    },
    '#nav': {
      background: "hsla(0, 0%, 0%, 0.0)",
      pointerEvents: 'none',
      duration: 0.45,
      delay: 0.25,
      ease: 'power3.out'
    }
  };

  isNavOpen = toggleAnimation(isNavOpen, openAnimation, closeAnimation);
  navToggle.classList.toggle('navbar__active');
}

navToggle.addEventListener('click', toggleNav);

let lastScrollDirection = 'up';

ScrollTrigger.create({
  start: 'top top',
  end: 'bottom bottom',
  onUpdate: (self) => {
    const currentScrollDirection = self.direction === 1 ? 'down' : 'up';

    if (currentScrollDirection !== lastScrollDirection) {
      if (currentScrollDirection === 'down' && isNavOpen) {
        toggleNav();
      }
      lastScrollDirection = currentScrollDirection;
    }
  }
});

// Initialization function
const init = () => {
	initSmoothScrolling(); // Initialize Lenis for smooth scrolling
	scroll(); // Apply scroll-triggered animations
	slideshow(); // Initialize slideshow

  console.log(asciiArt);
};

const cleanup = () => {
  if (gsapTicker) {
    gsap.ticker.remove(gsapTicker);
  }
  if (lenis) {
    lenis.destroy();
  }
  ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  window.removeEventListener("resize", debouncedRefresh);
};

const onLoad = () => {
  init();
  ScrollTrigger.refresh();
  window.addEventListener("resize", debouncedRefresh);
};

const debouncedRefresh = debounce(() => {
  ScrollTrigger.refresh();
}, 200);

function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

document.addEventListener("DOMContentLoaded", onLoad);

window.addEventListener("beforeunload", cleanup);
